import { withtRatification, withDocumentsReview } from '../constants';
import dayjs from '../config/dayjs';

const useGetStepsFromInvoiceObject = (invoice) => {
  const statusDatetime = (status) => {
    const processingHistoryStatus = invoice.historystatusSet.find(
      (invoiceHistoryStatus) => invoiceHistoryStatus.status === status,
    );
    return processingHistoryStatus
      ? dayjs(processingHistoryStatus.createdAt)
        .format('DD/MM/YYYY HH:mm')
        .replace('.', '')
      : '';
  };

  const invoiceActualStatus = invoice.lastHistoryStatus?.status || invoice.status;
  const transferCondition = invoice?.offer?.transferCondition;

  const statusSteps = [
    {
      id: `${invoice.id}-PROCESSING-step`,
      status: 'PROCESSING',
      startedDatetime: statusDatetime('Processing'),
      extraComponent: null,
      actualState: false,
      finished: false,
    },

    {
      id: `${invoice.id}-PENDINGTRANSFER-step`,
      status: 'PENDINGTRANSFER',
      startedDatetime: statusDatetime('PendingTransfer'),
      extraComponent: null,
      actualState: false,
      finished: false,
    },
    {
      id: `${invoice.id}-TRANSFERED-step`,
      status: 'TRANSFERED',
      startedDatetime: statusDatetime('Transfered'),
      actualState: false,
      finished: false,
      extraComponent: null,
    },
    {
      id: `${invoice.id}-FINISHED-step`,
      status: 'FINISHED',
      startedDatetime: statusDatetime('Finished'),
      actualState: false,
      finished: statusDatetime('Finished'),
      extraComponent: null,
    },
  ];

  if (transferCondition) {
    if (withtRatification.includes(transferCondition)) {
      const ratificationStep = {
        id: `${invoice.id}-PENDINGRATIFICATION-step`,
        status: 'PENDINGRATIFICATION',
        startedDatetime: statusDatetime('PendingRatification'),
        extraComponent: null,
        actualState: false,
        finished: false,
      };
      statusSteps.splice(1, 0, ratificationStep);
    }

    if (withDocumentsReview.includes(transferCondition)) {
      const documentReviewStep = {
        id: `${invoice.id}-PENDINGDOCUMENTSREVIEW-step`,
        status: 'PENDINGDOCUMENTSREVIEW',
        startedDatetime: statusDatetime('PendingDocumentsReview'),
        extraComponent: null,
        actualState: false,
        finished: false,
      };
      statusSteps.splice(1, 0, documentReviewStep);
    }
  }

  const actualStatusIndex = statusSteps.findIndex(
    (step) => step.status === invoiceActualStatus.toUpperCase(),
  );

  if (actualStatusIndex !== -1) {
    statusSteps[actualStatusIndex].actualState = true;
    for (let i = 0; i < actualStatusIndex; i += 1) {
      statusSteps[i].finished = true;
    }
  }
  return statusSteps;
};

export default useGetStepsFromInvoiceObject;
