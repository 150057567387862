import { useCallback, useState } from 'react';
import dayjs from '../config/dayjs';

const useLastRefreshDate = () => {
  const [lastUpdated, setLastUpdated] = useState(
    dayjs().format('DD/MM/YYYY - HH:mm:ss'),
  );
  const refreshDateHandler = useCallback(() => {
    const timestamp = dayjs().format('DD/MM/YYYY - HH:mm:ss');
    setLastUpdated(timestamp);
  }, []);
  return [lastUpdated, refreshDateHandler];
};

export default useLastRefreshDate;
