import { useCallback, useState } from 'react';
import { formatDateTime } from '../helpers';
import dayjs from '../config/dayjs';

const useLocalStorageTimer = (storageVariableName) => {
  const getLastTime = useCallback(() => {
    const lastShown = localStorage.getItem(storageVariableName);
    if (!lastShown) return dayjs('2020-01-01');
    return dayjs(lastShown);
  }, []);
  const [actualTime, setActualTime] = useState(getLastTime());
  const setLastTime = useCallback((lastShown) => {
    localStorage.setItem(storageVariableName, formatDateTime(lastShown));
    setActualTime(getLastTime());
  }, []);
  return {
    resetTimer: useCallback(() => {
      setLastTime(dayjs());
    }, []),
    lastTimeStored: actualTime,
  };
};

export default useLocalStorageTimer;
