import { useEffect, useRef } from 'react';

const useDebounce = (callback, delay) => {
  const timeoutRef = useRef(null);

  const debouncedCallback = (...args) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current); // Limpiar el timeout anterior
    }

    timeoutRef.current = setTimeout(() => {
      callback(...args); // Ejecutar la función después del retraso
    }, delay);
  };

  // Limpia el timeout cuando el componente se desmonta
  useEffect(
    () => () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    },
    [],
  );

  return debouncedCallback;
};

export default useDebounce;
